//Media query manager

// 0-600px: Phone
// 600-900px: Tab portrait
// 900-1200px: Tab Landscape
// [1200-1800px]: laptop/desktop
// 1800+: big desktop

/* 
- phone
- tab-port
- tab-land
- big-desktop

*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}

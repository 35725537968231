.sn-container {
  padding: 74px 0 91px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: #f2f7ff;
  .transformation-workflow-subscribe {
    * {
      font-family: "Merriweather Sans", sans-serif;
    }
    display: flex;
    flex-flow: row nowrap;
    input {
      width: 198px;
      height: 46px;
      border: 0.66px solid #abc0e3;
      border-top-left-radius: 6.59px;
      border-bottom-left-radius: 6.59px;
      background: #f5f9ff;
      padding-left: 24px;
      color: #9097d8;
      font-size: 12px;
    }
    span {
      width: 140px;
      align-self: center;
      background-color: #1e46ca;
      color: #fff;
      padding: 15px 0;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 19px;
      cursor: pointer;
      border-top-right-radius: 6.59px;
      border-bottom-right-radius: 6.59px;
    }
  }
  .sn-title {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #182b68;
    margin-bottom: 11px;
  }
  .sn-desc {
    font-family: "Merriweather Sans", sans-serif;
    font-weight: normal;
    font-size: 16.8197px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #263238;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .sn-container {
    padding-left: 37px;
    padding-right: 37px;
    .sn-title {
      font-size: 24px;
      line-height: 30px;
      width: 70%;
    }
    .transformation-workflow-subscribe {
      width: 100%;
    }
  }
}

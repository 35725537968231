@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
:root {
  --latoFont: "Lato";
  --merriFont: "Merriweather Sans";
  --notoFont: "Noto Sans";
  --openFont: "Open Sans";

  --white: #fff;
  --offWhite: #c2cdff;
  --lightWhite: #fafcff;
  --blue: #303f9f;
  --blue1: #1e46ca;
  --blue2: #040c58;
  --blue3: #0d195b;
  --offBlue: #0f2673;
  --darkBlue: #182b68;
  --fadeBlue: #f2f7ff;
  --dark: #263238;
  --dark1: #3d3d3d;
  --dark2: #37474f;
  --dark3: #2b324f;
}
// #header {
//   button.hamburger:not(.is-active) {
//     .hamburger-inner,
//     .hamburger-inner:after,
//     .hamburger-inner:before {
//       background-color: #fff !important;
//     }
//   }
// }
* {
  margin: 0;
  word-spacing: 0.2px;
}

#root:nth-child(1) {
  display: flex;
  flex-direction: column;
}

.card:hover {
  /* transform: scale(1.1); */
  border: 0.5px solid #b8bbd8;
  box-shadow: 0px 2px 6px 1px rgba(184, 187, 216, 0.29);
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
}
@media (min-width: 768px) {
  .menuToggle,
  .mob-nav-buttons {
    display: none;
  }
  .mobile-only {
    display: none !important;
  }
}

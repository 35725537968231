.transformation-container {
  background: #f5f8fc;
  height: 505px;
  padding: 100px 0rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 142rem;
    margin: 0 auto;
  .transformation-title {
    font-family: "Lato", sans-serif;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0.3px;
    color: #182b68;
    margin-bottom: 60px;
  }
  .transformation-items {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .transformation-workflow {
      margin-right: 45px;
      padding: 56px 51px 55px 56px;
      height: 300px;
      width: 60%;
      background: #ffffff;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      .transformation-workflow-list-wrapper {
        margin-left: 74px;

        flex-grow: 1;
        display: flex;
        flex-flow: column nowrap;
        .transformation-workflow-title {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 700;
          font-size: 22px;
          letter-spacing: 0.6px;
          color: #263238;
          margin-bottom: 46px;
        }
        .transformation-workflow-list {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;

          .transformation-workflow-item {
            margin-bottom: 28px;
            display: flex;
            align-items: center;
            span {
              font-family: "Merriweather Sans", sans-serif;
              font-size: 16px;
              font-weight: 300;
              margin-left: 16px;
              color: #37474f;
            }
          }
        }
        .transformation-workflow-subscribe {
          &:hover {
            border: 0.659185px solid #365cd9;
            box-shadow: 0px 5px 9px rgba(70, 79, 103, 0.14);
            width: fit-content;
            border-radius: 6.59185px;

            input {
              border: none;
            }
            span {
              background: #1e46ca;
              color: #fff;
              img {
                margin-left: 5px;
              }
            }
          }
          * {
            font-family: "Merriweather Sans", sans-serif;
          }
          display: flex;
          flex-flow: row nowrap;
          input {
            width: 198px;
            height: 46px;
            border: 0.66px solid #abc0e3;
            border-top-left-radius: 6.59px;
            border-bottom-left-radius: 6.59px;
            background: #f5f9ff;
            padding-left: 24px;
            color: #9097d8;
            font-size: 12px;
            outline: none;
          }
          span {
            width: 140px;
            align-self: center;
            background-color: #1e46ca;
            color: #fff;
            padding: 15px 0;
            text-align: center;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.5px;
            line-height: 19px;
            cursor: pointer;
            border-top-right-radius: 6.59px;
            border-bottom-right-radius: 6.59px;
          }
        }
      }
    }
    .transformation-app {
      * {
        font-family: "Merriweather Sans", sans-serif;
      }
      height: 412px;
      width: 40%;
      background: #eaf0ff;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      span:first-child {
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.5px;
        color: #263238;
        margin-bottom: 13px;
      }
      span:nth-child(2) {
        color: #37464f;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.3px;
        margin-bottom: 18.19px;
      }
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .transformation-workflow {
    > img {
      max-width: 100%;
      width: 44%;
      height: auto;
    }
  }
  .transformation-container .transformation-items .transformation-workflow {
    padding: 56px 33px 55px 33px !important;
  }
  .transformation-container
    .transformation-items
    .transformation-workflow
    .transformation-workflow-list-wrapper
    .transformation-workflow-subscribe
    input {
    width: 155px !important;
  }
  .transformation-container
    .transformation-items
    .transformation-workflow
    .transformation-workflow-list-wrapper
    .transformation-workflow-subscribe
    span {
    width: 100px !important;
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .footer-container {
    padding: 62px 5rem 78px 5rem!important;
  }
}

@media only screen and (max-width: 767px) {
  .transformation-container {
    padding: 80px 25px;
    height: auto;
    .transformation-title {
      text-align: center;
      font-size: 24px;
    }
    .transformation-items {
      flex-flow: column nowrap;
      .transformation-app {
        width: auto;
        margin-top: 30px;
      }
      .transformation-workflow {
        width: 100%;
        height: auto;
        align-items: center;
        > img {
          max-width: 100%;
          width: 70%;
        }
        padding: 0;
        flex-flow: column nowrap;
        .transformation-workflow-list-wrapper {
          margin-left: 0px;
          .transformation-workflow-subscribe {
            margin-bottom: 40px;
          }
          .transformation-workflow-subscribe input {
            width: 161px;
            height: 36px;
          }
          .transformation-workflow-subscribe span {
            width: 110px;

            padding: 10px 0;

            line-height: 20px;
          }
          .transformation-workflow-title {
            text-align: center;
            font-size: 16px;
            margin-top: 22px;
            margin-bottom: 22px;
          }
          .transformation-workflow-list {
            align-items: center;
          }
        }
      }
    }
  }
}

.fp-of-container {
  padding: 100px 48px 100px 102px;
  .fp-of-title {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.3px;
    color: #182b68;
    margin-bottom: 50px;
    display: inline-block;
  }
  .fp-of-cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-wrap: nowrap;
    a {
      width: 25%;
      padding: 0px 15px;
      text-decoration: none;
      display: flex;
      }
    .fp-of-card {
      background: #ffffff;
      box-shadow: 0px 2px 6px 1px rgba(184, 187, 216, 0.29);
      backdrop-filter: blur(14px);
      border-radius: 6px;
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-flow: column nowrap;
      padding: 40px 10px;
      align-items: center;
      .fp-of-card-img {
        margin-bottom: 23px;
      }
      .fp-of-card-text {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        color: #263238;
      }
    }
  }
}
.react-tabs__tab {
  font-size: 16px;
  font-weight: 600;
  font-family: "Merriweather Sans";
  color: rgb(34, 77, 217);
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 20px;
  cursor: pointer;
  outline: none !important;
  position: relative;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  max-width: 894px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.react-tabs__tab--selected {
  border-color: transparent;
  background: transparent;
  color: rgb(34, 77, 217);
}

.react-tabs__tab:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2a54da;
  position: absolute;
  bottom: 1px;
  left: -100%;
  transition: .5s;
  opacity: 0;
}
.react-tabs__tab.react-tabs__tab--selected:before {
  width: 100%;
  left: 0;
  transition: .1s;
  opacity: 1;
}
.text-center{text-align: center;width:100%;}
.react-tabs__tab-list {
  border-bottom: 1px solid #C3D7F9;
}
.react-tabs__tab-panel {
  margin-top: 5%;
  width: 100%;
  margin-bottom: 5%;
}
.fp-of-card-img img {
  width: 50px;
}
.fp-of-container.new-container {
  padding: 100px 0px 0 !important;
}
.cta-wrapper a{
  width: 100% !important;
  margin-top: 15px;
}
@media only screen and (max-width: 767px) {
  .fp-of-card:hover .cta-wrapper {
    margin-left: 1.5rem;
    transition: .5s;
}
  .fp-of-container {
    padding: 50px 20px;
    .fp-of-title {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 30px;
    }
    .fp-of-cards {
      justify-content: space-between;
      .fp-of-card {
        transition: .5s;
        margin-bottom: 15px;
        margin-right: 0;
        align-items: flex-start;
        padding: 20px ;
        .fp-of-card-img {
          margin-bottom: 16px;
        }
        .fp-of-card-text {
          font-size: 16px;
          line-height: 22px;
          text-align: left;
        }
      }
    }
  }
  .fp-of-container .fp-of-cards{flex-wrap: wrap;}
  .fp-of-container .fp-of-cards a {
    width: 50%;
  }
  .react-tabs__tab-list {
    overflow-x: auto;
  }
  .react-tabs__tab{white-space: nowrap;}
  .fp-of-container.new-container {
    padding: 7rem 0 !important;
}
section.faqsBlock.space-14.wow.fadeInUp{
  padding: 7rem 0 !important;
}
.mobile-tabs.mobile-only {
  padding: 0px 15px;
}
.solutions-dropdown {
  border: none;
  font-size: 16px;
  font-weight: 800;
  font-family: "Merriweather Sans", sans-serif;
  color: #224dd9;
  letter-spacing: 0.5px;
  background: transparent;
}
.mobile-tabs {
  border-bottom: 2px solid #224dd9;
  width: 95%;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  box-sizing: border-box;
  background-color: transparent;
  margin-bottom: 20px;
}
}
@media only screen and (min-width: 1100px) and (max-width: 1550px) {
  .fp-of-container {
    padding: 80px 10px 80px 50px;
    .fp-of-cards {
      .fp-of-card {
        margin-bottom: 20px;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

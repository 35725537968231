@import "../../../../sass/abstracts/_mixins";

.solutions-container {
  padding: 100px 5rem 100px 5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .sticky-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    @include respond(phone) {
      .sticky-top {
        top: 8rem !important;
        z-index: 99;
      }
    }
    .tabfix {
      max-width: 600px;
      margin: 0 auto;
    }
    .tab-group-custom {
      border-bottom: 0.5px solid #a5b8f8;
    }
  }
  .solutions-title {
    font-family: "Lato", sans-serif;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0.5px;
    color: #182b68;
    margin-bottom: 45px;
  }
  .solutions-items {
    margin-top: 80px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    max-width: 142rem;
    > span {
      // flex-basis: 50%;
    }
    > span:nth-child(1) {
      margin-bottom: 82.7px;
    }
    > span:nth-child(4) {
      margin-bottom: 82.7px;
      img {
        display: block;
        margin: 15% auto 0;
      }
    }
    .sol-img {
      flex-basis: 50%;
      img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
    .sol-content {
      align-self: center;
      flex-basis: 40%;

      > span:nth-child(1) {
        display: inline-block;
        color: #263238;
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-size: 28px;
        letter-spacing: 0.5px;
        margin-bottom: 66px;
      }
      > span:last-child {
        &:hover {
          text-decoration: underline;
        }
        display: flex;
        font-family: "Merriweather Sans", sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: #1e46ca;
        margin-top: -8px;
        cursor: pointer;

        img {
          margin-left: 10px;
          align-self: center;
        }
      }
      .sol-content-item {
        span:nth-child(1) {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 800;
          font-size: 18px;
          color: #1f3641;
          background-image: linear-gradient(
            to right,
            transparent 50%,
            #e3e9ff 50%
          );
          display: inline;
          background-origin: 0;
          background-size: 200% 35%;
          background-repeat: repeat-x;
          background-position: 0 100%;
          transition: background-position 0.5s;
          background-position: -100% 100%;
        }
        span:nth-child(2) {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 300;
          font-size: 14px;
          color: #43515e;
          width: 81%;
          margin-top: 4px;
          margin-bottom: 43px;
        }
        span {
          display: inline-block;
        }
      }
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .solutions-container .solutions-items .sol-img {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }
  .solutions-container .solutions-items .sol-content > span:nth-child(1) {
    margin-bottom: 26px !important;
  }
  .solutions-container
    .solutions-items
    .sol-content
    .sol-content-item
    span:nth-child(2) {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .solutions-container .solutions-items .sol-content {
    margin-bottom: 76px !important;
  }

  .solutions-container .solutions-items > span:nth-child(4) {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .solutions-container {
    padding: 100px 0px 100px 0px;
    .sol-dropdown-wrapper {
      border-bottom: 2px solid #224dd9;
      width: 95%;
      display: flex;
      justify-content: center;
      padding-bottom: 5px;
      box-sizing: border-box;
      .solutions-dropdown {
        border: none;
        font-size: 16px;
        font-weight: 800;
        font-family: "Merriweather Sans", sans-serif;
        color: #224dd9;
        letter-spacing: 0.5px;
      }
    }
    .solutions-items {
      flex-flow: column;
      .sol-img {
        display: flex;
        justify-content: center;
        img {
          max-width: 70%;
          margin-bottom: 25px;
        }
      }
      .sol-content {
        padding-left: 25px;
        margin-bottom: 40px;
        > span:nth-child(1) {
          width: 100%;
          text-align: center;
          margin-left: 0px;
          margin-bottom: 33px;
        }
        .sol-content-item span:nth-child(2) {
          margin-bottom: 21px;
          width: 90%;
        }
      }
      > span:nth-child(1) {
        order: 1;
      }
      > span:nth-child(2) {
        order: 2;
      }
      > span:nth-child(3) {
        order: 4;
      }
      > span:nth-child(4) {
        order: 3;
      }
      > span:nth-child(5) {
        margin-bottom: 40px;
        order: 5;
      }
      > span:nth-child(6) {
        order: 6;
      }
    }
  }
}
.row-flex {
  display: flex;
  align-items: center;
}
.row-flex .col-sm-6 {
  flex-basis: 50% !important;
}
.pr-5 {
  padding-right: 5% !important;
}
.pl-5 {
  padding-left: 5% !important;
}
.m0 {
  margin: 0 !important;
}
.my-5 {
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}
@media only screen and (max-width: 767px) {
  .row-flex {
    flex-wrap: wrap;
  }
  .row-flex .col-sm-6 {
    flex-basis: 100% !important;
  }
  .row-revers {
    flex-direction: column-reverse;
  }
  .my-5 {
    margin-top: 10% !important;
    margin-bottom: 20% !important;
  }
  .res-margin-0 {
    margin-bottom: 0 !important;
  }
}

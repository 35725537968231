.cp-ds-stats-container {
  * {
    font-family: "Lato", sans-serif;
  }

  display: flex;
  flex-flow: column nowrap;
  height: 217px;
  padding: 100px 5rem 0;
  align-items: center;
  background: #fff;

  .ds-stats-items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1020px;
    .ds-stats-item {
      display: flex;
      flex-flow: column nowrap;
      .ds-stats-item-head {
        font-weight: 900;
        font-size: 45px;
        letter-spacing: 0.3px;
        color: #1e46ca;
        .item-head-extra {
          font-size: 38px;
        }
      }
      .ds-stats-item-body {
        font-family: "Merriweather Sans", sans-serif;
        font-size: 22px;
        font-weight: 400;
        line-height: 34.02px;
        letter-spacing: 0.3px;
        color: #37464f;
      }
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .ds-stats-container .ds-stats-items .ds-stats-item .ds-stats-item-head {
    font-size: 45px !important;
  }
  .ds-stats-container .ds-stats-items .ds-stats-item .ds-stats-item-body {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 767px) {
  .erfw-container {
    padding: 80px 0 !important;
  }
  .erfw-container .erfw-title {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  .erfw-container .erfw-cards-list .erfw-card {
    width: 285px !important;
    padding: 30px 20px !important;
    margin-bottom: 23px !important;
  }
  .erfw-container .erfw-cards-list .erfw-card .erfw-card-title {
    font-size: 16px !important;
    line-height: 26px !important;
  }
  .erfw-container .erfw-cards-list .erfw-card .erfw-card-desc {
    font-size: 14px !important;
    line-height: 17px !important;
  }
  .cp-ds-stats-container {
    padding: 80px 28px;
    .ds-stats-title {
      font-size: 24px;
      text-align: center;
      margin-bottom: 30px;
    }

    .ds-stats-items {
      flex-flow: row wrap;
      > div:nth-child(1),
      > div:nth-child(2) {
        margin-bottom: 37px;
      }
      .ds-stats-item {
        flex-basis: 50%;
        align-items: center;
        .ds-stats-item-head {
          font-size: 30px;
          .item-head-extra {
            font-size: 18px;
          }
        }
        .ds-stats-item-body {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
@media only screen and (max-width:480px) {

 .erfw-container .erfw-cards-list .erfw-card{width:100% !important;}
 .erfw-container {
  padding: 50px 015px!important;
}
}
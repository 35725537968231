.pricingpage-container {
  .space-14 {
    padding: 5rem 0;
    .container {
      max-width: 132rem;
      margin: 0 auto;
      padding: 0 3rem;
      .row {
        h2 {
          margin-bottom: 3rem;
          text-align: center;
          font-size: 2rem;
          line-height: 3.5rem;
          letter-spacing: 0.3px;
          font-weight: 900;
          color: var(--darkBlue);
          font-family: var(--latoFont);
        }
        .faqsList {
          max-width: 764px;
        }
      }
      h3.large {
        font-size: 1.4rem;
        letter-spacing: 0.1px;
        line-height: 2.2rem;
        color: var(--dark);
        font-weight: 500;
        font-family: var(--merriFont);
      }
      .faqsList p {
        font-size: 0.9rem;
        line-height: 1.2rem;
        color: var(--dark3);
        font-weight: 300;
        letter-spacing: 0.3px;
      }
      .faqsList .wc-text a {
        font-weight: 700;
        color: var(--blue1);
      }
      .faqsList .wc-text {
        padding: 1rem 0 0.5rem;
        margin-top: 1.2rem;
      }
      .faqsList .item {
        margin-bottom: 2rem;
      }
    }
  }
}

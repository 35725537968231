.erfw-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 100px 0;
  background: #f5f8fc;
  .erfw-title {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #182b68;
    margin-bottom: 60px;
  }
  .erfw-cards-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 80%;
    .erfw-card {
      display: flex;
      flex-flow: column nowrap;
      padding: 45px 35px;
      background: #ffffff;
      box-shadow: 0px 2px 6px 1px rgba(184, 187, 216, 0.29);
      backdrop-filter: blur(14px);
      border-radius: 6px;
      max-width: 460px;
      width: 40%;
      .erfw-card-title {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: #263238;
        margin-bottom: 18px;
      }
      .erfw-card-desc {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #37474f;
      }
    }
  }
}

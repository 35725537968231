.pricingpage-container {
  display: flex;
  flex-direction: column;
  border: none;
  .cp-navbar-container {
    font-family: "Open Sans", sans-serif;
    color: #fff;
  }
  > div:nth-child(2) {
    margin-top: 60px;
  }
  .siteHeader {
    &::before {
      height: 100%;
    }
    padding: 0.5rem 0;
  }
  .container {
    max-width: 132rem;
    margin: 0 auto;
    padding: 0 5rem;
    font-family: var(--merriFont);
    .row {
      .branding {
        .menuToggle {
          margin-right: 1rem;
          > img {
            width: 1.5em;
            height: 1.1em;
          }
        }
      }
    }
    a {
      color: #fff;
      text-decoration: none;
      font-size: 15px;
    }

    li {
      list-style: none;
    }
  }
  .d-flex {
    display: flex;
  }
  .mainNav li a {
    line-height: 2.5rem;
    padding: 0 1.5rem;
    font-weight: 300;
  }
  .mainNav li:last-child a {
    padding: 0 2rem;
    font-weight: 600;
  }
}
@media (min-width: 768px) {
  .menuToggle,
  .mob-nav-buttons {
    display: none;
  }
  .mobile-only {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .pricingpage-container {
    > div:nth-child(2) {
      margin-top: 60px;
    }

    .siteHeader {
      background: var(--blue3);
      padding: 1rem 0;
    }
    .siteHeader::before {
      display: none;
    }

    .siteHeader .branding {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .mainNav {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #fff;
      padding-top: 9rem;
      transform: translateX(100%);
      transition: 0.4s;
    }
    .mainNav ul {
      display: block;
    }

    .mainNav li:last-child a {
      margin: 3rem 3rem 0;
      text-align: center;
    }
    .mainNav li a {
      line-height: 2.5rem;
      font-size: 1.6rem;
    }

    .container {
      padding: 0 !important;
      a {
        z-index: 2;
        text-decoration: none;
      }
    }

    .mainNav {
      padding-top: 5rem;
      ul {
        flex-flow: column;
        list-style: none;
        .navlink-mobile-only {
          display: block !important;
        }

        li {
          &:first-child {
            display: none;
          }
          padding: 20px 0;
          border-bottom: 1px solid #efefef;
          width: 80%;
          margin-bottom: 0rem !important;
          a {
            padding-left: 0;
          }
        }
      }
    }

    .mainNav li a {
      color: #181f39;
      font-weight: 600;
      font-size: 16px;
      font-family: var(--merriFont);
    }
    .hamburger {
      z-index: 2;
      outline: none;
      padding: 0;
      .hamburger-box {
        top: 2px;
      }
      .hamburger-inner {
        margin-top: -1px;
        width: 35px;
        background: #fff;
        height: 3.5px;

        &::after {
          bottom: -8px;
          width: 35px;
          background: #fff;
          height: 3.5px;
        }
        &::before {
          top: -8px;
          width: 35px;
          background: #fff;
          height: 3.5px;
        }
      }
    }
    .is-active {
      .hamburger-inner {
        background: #000;
        margin-top: 0;
        width: inherit;

        &::after {
          bottom: 0;
          width: inherit;
          height: inherit;
          background: #000;
        }
        &::before {
          background: #000;
        }
      }
    }

    .siteHeader > div:first-child {
      width: 100%;
    }
    .branding img {
      max-width: 8.5rem;
    }

    .mob-ask-for-demo {
      color: var(--blue) !important;
      background: var(--white);
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 0.2rem;
      font-family: var(--openFont);
      line-height: 2.5rem;
    }
    span.mob-nav-buttons {
      display: flex;
      align-items: center;
    }
  }
  .menu-active {
    .mainNav li:last-child {
      display: none;
    }
  }
  .menu-active .mob-ask-for-demo {
    color: var(--white) !important;
    padding: 0 1rem;
    font-weight: 600;
    line-height: 2.5rem;
    background: #1e46ca;
    border-radius: 0.2rem;
    font-family: var(--openFont);
  }
  .menu-active .mainNav {
    transform: translateX(0);
  }
  .menu-active {
    overflow: hidden;
  }
}

.footer-container {
  height: auto;
  background-color: #040c58;
  padding: 62px 109.4px 78px 60px;
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .footer-left {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    > img {
      margin-bottom: 32px;
      width: 158px;
    }
    &-terms {
      font-size: 14px;
      font-weight: 300;
      font-family: "Merriweather Sans", sans-serif;
      margin-top: 10px;
      color: #e5e5e5;
      > a {
        font-size: 14px;
        font-weight: 300;
        font-family: "Merriweather Sans", sans-serif;
        color: #e5e5e5;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .footer-left-social {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      align-items: center;
      .footer-left-text {
        font-size: 14px;
        font-weight: 400;
        font-family: "Merriweather Sans", sans-serif;
        letter-spacing: 0.5px;
      }
      .footer-left-seperator {
        border: 1px solid #6b76b5;
        width: 29px;
        height: 0px;
        transform: rotate(90deg);
      }
      .footer-left-social-icons {
        > img {
          margin-right: 38.5px;
        }
      }
    }
  }
  .footer-right {
    width: 30%; //added
    display: flex;
    justify-content: space-between; //added
    flex-flow: row wrap;
    align-self: center; //added
    // align-items: flex-end;
    span {
      flex-basis: 33%;
      font-family: "Merriweather Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.5px;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
footer {
  background: #040c58;
}
.footer-container {
  max-width: 142rem;
  margin: 0 auto;
  padding: 62px 0;
}
@media only screen and (max-width: 767px) {
  .footer-container {
    padding: 62px 25px 25px 25px;
    flex-flow: column nowrap;
    height: auto;

    .footer-right span {
      flex-basis: 50%;
      margin-bottom: 25px;
      font-size: 14px;
      max-width: 20%; //added
    }
    .footer-right {
      display: flex;
      justify-content: space-evenly;
      align-self: center;
      width: 100%;
    } //added
    .footer-left {
      margin-bottom: 30px;
      > img {
        margin-bottom: 15px;
      }
      .footer-left-social {
        flex-flow: column nowrap;
        .footer-left-text {
          width: 100%;
          margin-bottom: 17px;
        }
        .footer-left-seperator {
          display: none;
        }
        .footer-left-social-icons > img {
          margin-right: 17px;
        }
      }
    }
  }
}

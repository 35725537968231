.cp-header-container {
  height: 100vh;
  //   background: url("../../../../assets/images/header-bg-combined.png") center
  // center no-repeat;
  font-family: "Open Sans", sans-serif;
  //   background-size: cover;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  .cp-hero-container {
    > img {
      width: 100%;
      max-width: 100%;
      height: 73.5vh;
    }
    flex-grow: 1;
    .cp-hero-content {
      height: 195px;
      width: 90%;
      margin: 0 auto;
      position: relative;
      bottom: 15%;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding: 80px 0 55px 0;
      max-width: 142rem;
      .cp-hero-title {
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #182b68;
        margin-bottom: 12px;
      }
      .cp-hero-desc {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #3d3d3d;
        width: 40%;
        margin-bottom: 35px;
      }
      .cp-hero-btn {
        width: 263px;
        max-width: 100%;
        min-height: 45px;
        height: 45px;
        font-family: "Merriweather Sans", sans-serif;
        font-weight: bold;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        background: #1e46ca;
        box-shadow: 0px 6px 6px #e3e9ff;
        border-radius: 6.59185px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .cp-header-container {
    // height: calc(150vh - 171px);
    height: auto;
    background-color: #040c58;
  }
  .cp-header-container .cp-hero-container > img {
    object-fit: cover;
    position: absolute;
  }

  .cp-header-container .cp-hero-container .cp-hero-content {
    top: 0;
    margin-top: 75%;

    height: auto;
    padding: 50px 0 50px 0;
  }
  .cp-header-container .cp-hero-container .cp-hero-content .cp-hero-title {
    font-size: 24px;
    line-height: 30px;
  }
  .cp-header-container .cp-hero-container .cp-hero-content .cp-hero-desc {
    width: 90%;
  }
  .cp-header-container .cp-hero-container .cp-hero-content .cp-hero-btn {
    min-height: 36px;
    width: 241px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .header-container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  .header-container .header-content {
    // position: unset;
    // margin-left: 7%;
    // margin-bottom: 8%;
  }
}
.cp-header-container .cp-hero-container .cp-hero-content .cp-hero-btn{
  text-decoration: none;
}

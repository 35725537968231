.header-container {
  height: 92vh;
  background: url("../../../../assets/images/home-banner-bg.jpg") center center
    no-repeat;
  font-family: "Open Sans", sans-serif;
  background-size: cover;
  color: #fff;
  .header-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0%;
    margin: auto;
    width: 444px;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    .header-content-title {
      font-family: "Lato", sans-serif;
      font-weight: 900;
      font-size: 50px;
      line-height: 60.7px;
      letter-spacing: 0.5px;
      margin-bottom: 17px;
    }
    .header-content-desc {
      font-family: "Merriweather Sans", sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #b8bbd8;
      margin-bottom: 17px;
    }
    .header-content-input {
      border-radius: 2px;
      display: flex;
      border: 0.5px solid transparent;
      &:hover {
        border: 0.5px solid #37464f;
        border-radius: 6.59185px;
        // border-right: none;
        // width: fit-content;
        input {
          border-color: transparent;
        }
        span {
          background: #1e46ca;
          color: #fff;
          img {
            margin-left: 5px;
          }
        }
      }
      input {
        font-family: "Merriweather Sans", sans-serif;
        color: #fff;
        padding-left: 24px;
        height: 62px;
        width: 245px;
        font-size: 15px;
        border-top-left-radius: 6.59px;
        border-bottom-left-radius: 6.59px;
        border: 0.66px solid #606980;
        background: transparent;
        outline: none;
      }
      span {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: 600;
        border-top-right-radius: 6.59px;
        border-bottom-right-radius: 6.59px;
        align-self: center;
        padding: 22px 0;
        text-align: center;
        width: 171px;
        background-color: #fff;
        color: #1e46ca;
        cursor: pointer;
        margin-left: auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-container {
    background: url("../../../../assets/images/home-banner-bg-mob.jpg") center
      center no-repeat;
    background-size: cover;
    .fatcat-mob-head {
      display: flex;
      width: 100%;
      flex-grow: 1;
      justify-content: center;
      padding-top: 20px;
      flex-basis: 9%;
      > img {
        width: 80%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .header-container .header-content {
    margin: 0 auto;
    top: 20%;
    left: 0;
    right: 0;
    align-items: center;
    display: block;
    width: 89%;
    flex-flow: unset !important;
    text-align: center;
    > span:first-child {
      font-size: 30px;
      text-align: center;
      line-height: 36px;
      display: block;
    }
    > span:nth-child(2) {
      font-size: 13px;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 25px;
      box-sizing: border-box;
      display: block;
    }
    .header-content-input input {
      height: 56px;
      width: 183px;
    }
    .header-content-input span {
      line-height: 21px;
      width: 126px;
      padding: 19px 14px;
      height: 21px;
      font-size: 13px;
    }
  }
  .desktop-only {
    display: none !important;
  }
}
.home-head .rightImage {
  width: 50%;
  position: absolute;
  right: 0%;
  align-items: center;
  height: 100%;
  display: flex;
  animation-name: banner-img;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

@keyframes banner-img {
  0% {
    // right: 0px;
    top: 0px;
  }
  25% {
    // right: 20px;
    top: 20px;
  }
  50% {
    // right: 20px;
    top: 0px;
  }
  75% {
    // right: 0px;
    top: 20px;
  }
  100% {
    // right: 0px;
    top: 0px;
  }
}

.home-head .rightImage img {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .header-container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  .header-container .header-content {
    // position: unset;
    // margin-left: 7%;
    // margin-bottom: 8%;
  }
}
@media (max-height: 568px) {
  .home-head.header-container {
    height: 113vh;
  }
}

.bg-1{background-color:#f5f8fc ;}
.ds-stats-container {
  * {
    font-family: "Lato", sans-serif;
  }

  display: flex;
  flex-flow: column nowrap;
  // height: 217px;
  padding: 100px;
  align-items: center;
  background: #f5f8fc;
  max-width: 142rem;
    margin: 0 auto;
  .ds-stats-title {
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0.3px;
    margin-bottom: 60px;
    color: #182b68;
  }
  .ds-stats-items {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    .ds-stats-item {
      display: flex;
      flex-flow: column nowrap;
      .ds-stats-item-head {
        font-weight: 900;
        font-size: 60px;
        letter-spacing: 0.3px;
        color: #1e46ca;
        .item-head-extra {
          font-size: 38px;
        }
      }
      .ds-stats-item-body {
        font-family: "Merriweather Sans", sans-serif;
        font-size: 22px;
        font-weight: 400;
        line-height: 34.02px;
        letter-spacing: 0.3px;
        color: #37464f;
      }
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .ds-stats-container .ds-stats-items .ds-stats-item .ds-stats-item-head {
    font-size: 45px !important;
  }
  .ds-stats-container .ds-stats-items .ds-stats-item .ds-stats-item-body {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ds-stats-container {
    padding: 80px 28px;
    .ds-stats-title {
      font-size: 24px;
      text-align: center;
      margin-bottom: 30px;
    }

    .ds-stats-items {
      flex-flow: row wrap;
      > div:nth-child(1),
      > div:nth-child(2) {
        margin-bottom: 37px;
      }
      .ds-stats-item {
        flex-basis: 50%;
        align-items: center;
        .ds-stats-item-head {
          font-size: 30px;
          .item-head-extra {
            font-size: 18px;
          }
        }
        .ds-stats-item-body {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
.custom-container {


  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
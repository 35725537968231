.cp-navbar-container {
  background: url("../../../../assets/images/nav-background.svg") center center
    no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 65px;
  padding: 0 40px 0 77px;
  border: none;
  .navbar-brand {
    cursor: pointer;
  }
  .navbar-menu-items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-basis: 566px;
    span {
      font-size: 15px;
      > a {
        color: inherit;
        text-decoration: none;
      }
    }
    span:last-child {
      font-size: 14px;
      cursor: pointer;
      background: #fff;
      color: #303f9f;
      font-weight: 600;
      line-height: 36px;
      padding: 0 23px;
      border-radius: 2px;
      margin-left: 15px;
    }
  }
}

.navbar-mobile-container {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  z-index: 99;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding: 0 25px 0 25px;
  background: #0d195b;
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-toggler-icon {
    background-image: url("../../../../assets/images/menu.svg");
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-size: 100% 100%;
  }

  .navbar-toggler {
    display: block;
  }

  div.navbar-links {
    margin-right: 0;
    position: absolute;
    width: 70%;
    background: #e3e9ff;
    z-index: 99;
    margin-top: 60px;
    float: right;
    right: 0;
    height: 100vh;
    transition: all ease-in-out 0.3s;
    align-self: flex-start;
    border-top: 2px solid maroon;
  }

  ul.links-list {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .navbar-links.menu-collapse {
    margin-right: -75%;
    width: 0;
    overflow: hidden;
  }

  li.nav-item {
    color: #fff;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0;
    margin-bottom: 10px;
    list-style: none;
    border-radius: 2px;
    background: #456bf1;
  }

  li.nav-item a.nav-link {
    color: #fff;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    padding: 10px 20px;
  }

  .menu-collapse {
    margin-right: -75%;
    width: 0;
    overflow: hidden;
  }
}

.dbs-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 100px 182px;
  background: #fff;
  > span {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #182b68;
    margin-bottom: 60px;
  }
  .dbs-safety-cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    .dbs-safety-card {
      display: flex;
      flex-flow: column nowrap;
      width: 262px;
      max-width: 100%;
      .dbs-safety-img {
        margin-bottom: 20px;
      }
      .dbs-safety-title {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.22px;
        color: #263238;
        margin-bottom: 8px;
      }
      .dbs-safety-desc {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #37474f;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .dbs-container {
    padding: 80px 35px;
    > span {
      font-size: 24px;
      line-height: 30px;
    }
    .dbs-safety-cards {
      .dbs-safety-card {
        flex-flow: row wrap;
        margin-bottom: 30px;
        .dbs-safety-img,
        .dbs-safety-title {
          margin-bottom: 0;
        }
        .dbs-safety-title {
          flex-grow: 1;
          align-self: center;
          padding-left: 15px;
        }
      }
    }
  }
}

#ask-demo-page {
  .faqsBlock h2 {
    margin-bottom: 6rem;
  }

  .faqsList {
    max-width: 76.4rem;
    margin: 0 auto;
  }

  .faqsList .item {
    box-shadow: 0px 0.4rem 0.9rem rgba(184, 187, 216, 0.25);
    border-radius: 0.9rem;
    padding: 2rem 3.3rem;
    margin-bottom: 4rem;
  }

  .faqsList .wc-text {
    border-top: 0.1rem solid #ecedf0;
    padding: 2rem 0 0.5rem;
    margin-top: 1.5rem;
  }
  .wc-text {
    display: none;
  }

  .faqsList .wc-text a {
    font-weight: 700;
    color: var(--blue1);
  }

  .faqsList h3 {
    margin: 0;
    padding-right: 6rem;
    cursor: pointer;
    position: relative;
  }
  .faqsList h3::after {
    content: url("../../../../assets/images/down-arrow.svg");
    position: absolute;
    right: 2.2rem;
    top: 0rem;
  }

  .faqsList p {
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--dark3);
    letter-spacing: 0.3px;
  }
  .wc-text {
    display: none;
  }
  .active + .wc-text {
    display: block;
  }
}

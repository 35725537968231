.home-banner-fix {
  max-width: 142rem !important;
  margin: 0 auto;
  padding: 0 0 !important;
  font-family: var(--merriFont);
  height: 100vh;
  width: 100%;
  position: relative;
}
.homepage-container {
  display: flex;
  flex-direction: column;
  .siteHeader {
    background-color:#040C58;
  }
  .container {
    max-width: 132rem;
    margin: 0 auto;
    padding: 0 5rem;
    font-family: var(--merriFont);
    .row {
      .branding {
        .menuToggle {
          margin-right: 1rem;
          > img {
            width: 1.5em;
            height: 1.1em;
          }
        }
      }
    }
    a {
      color: #fff;
      text-decoration: none;
      font-size: 15px;
    }

    li {
      list-style: none;
    }
  }
  .d-flex {
    display: flex;
  }

  @media (min-width: 768px) {
    .menuToggle,
    .mob-nav-buttons {
      display: none;
    }
    .mobile-only {
      display: none !important;
    }
  }
  @media (max-width: 767px) {
    .hamburger--squeeze.is-active .hamburger-inner:after {
      bottom: 0 !important;
    }
    .siteHeader {
      background: var(--blue3);
      padding: 1.2rem 0;
      height: 3rem;
    }
    .siteHeader::before {
      display: none;
    }
    body {
      padding-top: 3rem;
    }

    // .header-container .header-content .header-content-input:hover{width: unset;}
    .siteHeader .branding {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .mainNav {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #fff;
      padding-top: 9rem;
      transform: translateX(100%);
      transition: 0.4s;
    }
    .mainNav ul {
      display: block;
    }

    .mainNav li:last-child a {
      margin: 3rem 3rem 0;
      text-align: center;
    }
    .mainNav li a {
      line-height: 5rem;
      font-size: 1.6rem;
    }

    // .homepage-container {
    .container {
      padding: 0 !important;
      a {
        z-index: 2;
      }
    }
    // }
    // .homepage-container {
    .mainNav {
      padding-top: 5rem;
      ul {
        flex-flow: column;
        .navlink-mobile-only {
          display: block !important;
        }

        li {
          &:first-child {
            display: none;
          }
          padding: 20px 0;
          border-bottom: 1px solid #efefef;
          width: 80%;
          margin-bottom: 0rem !important;
          a {
            padding-left: 0;
          }
        }
      }
    }
    // }
    .mainNav li a {
      color: #181f39;
      font-weight: 600;
      font-size: 16px;
      font-family: var(--merriFont);
    }
    .hamburger {
      z-index: 2;
      outline: none;
      padding: 0;
      .hamburger-box {
        top: 2px;
      }
      .hamburger-inner {
        margin-top: -1px;
        width: 35px;
        background: #fff;
        height: 3.5px;

        &::after {
          bottom: -8px;
          width: 35px;
          background: #fff;
          height: 3.5px;
        }
        &::before {
          top: -8px;
          width: 35px;
          background: #fff;
          height: 3.5px;
        }
      }
    }

    .siteHeader > div:first-child {
      // width: 100%;
    }
    .branding img {
      max-width: 8.5rem;
    }

    .mob-ask-for-demo {
      color: #303f9f !important;
      background: var(--white);
      padding: 0 1.5rem;
      font-weight: 600;
      /* margin-left: 4rem; */
      border-radius: 0.2rem;
      font-family: var(--openFont);
      line-height: 4rem !important;
    }
    span.mob-nav-buttons {
      display: flex;
      align-items: center;
    }
  }
  .header-container .header-content .header-content-input span {
    font-size: 16px;
  }
}

.menu-active .mainNav {
  transform: translateX(0) !important;
}
.menu-active {
  overflow: hidden;
}
.menu-active .mob-ask-for-demo {
  color: var(--white) !important;
  padding: 0 1rem;
  font-weight: 600;
  line-height: 2.5rem;
  background: #1e46ca;
  border-radius: 0.2rem;
  font-family: var(--openFont);
}
.menu-active {
  .mainNav li:last-child {
    display: none;
  }
}


.terms {
  &__wrapper {
    padding-top: 9rem;
    background-color: #F5F8FC;
     padding-left: 5rem;
    padding-right: 5rem;
  }
  &__heading {
    font-size: 30px;
    font-family: var(--merriFont);
  }
  &__subHeading {
    font-size: 20px;
    font-family: var(--merriFont);
    color: #43515E;
    margin-top: 2rem;
  }
  &__description {
    font-family: var(--merriFont);
    font-size: 14px;
    font-weight: 300; 
    color: #43515E;
    margin-top: 2rem;
  }
  &__list {
    list-style-type:armenian;
    font-family: var(--merriFont);
    font-size: 14px;
    font-weight: 300; 
    color: #43515E; 
  }
}
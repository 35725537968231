.chooseplan-container {
  padding: 80px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .chooseplan-title {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #182b68;
    margin-bottom: 70px;
  }
  .chooseplan-cards {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: space-evenly;
    .chooseplan-card {
      margin-right: 30px;
      width: 400px;
      background: #ffffff;
      /* shadow illu */
      box-shadow: 0px 14.903px 24.8384px rgba(153, 156, 157, 0.25);
      border-radius: 6px;
      .premium-active {
        background: #182b68 !important;
        * {
          color: #fff !important;
        }
      }
      .chooseplan-card-head {
        display: flex;
        flex-flow: column nowrap;
        background: #f5f8fc;
        border-radius: 6px 6px 0px 0px;
        padding-top: 50px;
        padding-left: 50px;
        padding-bottom: 52px;
        .card-plan-tag {
          width: 136px;
          max-width: 100%;
          padding: 10px 0px;
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          text-transform: uppercase;

          color: #f5f8fc;

          background: #1da1f2;
          margin-bottom: 18px;
          border-radius: 20px;
        }
        .card-price-details {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #182b68;
          display: flex;
          flex-flow: column nowrap;
          > span {
            margin-bottom: 5px;
          }
          .card-rupee-symbol {
            font-family: "Merriweather Sans", sans-serif;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #182b68;
            position: relative;
            bottom: 20px;
          }
          .card-price-value {
            font-family: "Lato", sans-serif;
            position: relative;
            top: 5px;
            font-weight: 900;
            font-size: 50px;
            line-height: 61px;
            /* or 122% */

            letter-spacing: 0.5px;

            color: #182b68;
          }
        }
        .card-note {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;
          color: #182b68;
        }
      }
      .chooseplan-card-body {
        padding: 42px 51px;
        .chooseplan-card-body-items {
          display: flex;
          flex-flow: column nowrap;
          .chooseplan-card-body-item {
            align-items: center;
            display: flex;
            margin-bottom: 22px;
            .body-item-image {
              margin-right: 15px;
            }
            .body-item-text {
              font-family: "Merriweather Sans", sans-serif;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #263238;
            }
          }
        }
      }
      .chooseplan-card-footer {
        padding: 18px 0;
        display: flex;
        align-items: center;
        background: #f5f8fc;
        border-radius: 0px 0px 6px 6px;
        justify-content: center;
        .chooseplan-card-footer-text {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #182b68;
          > span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .chooseplan-container {
    .chooseplan-title {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 30px;
    }
    .chooseplan-cards {
      flex-flow: column nowrap;
      width: auto;
      padding: 0 20px;
      .chooseplan-card {
        width: 100%;
        margin-bottom: 50px;
      }
    }
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1425px) {
  .chooseplan-container .chooseplan-cards .chooseplan-card {
    margin-bottom: 50px;
    margin-right: 0;
  }
}

.future-digital-container {
  padding: 100px 0rem 100px 5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .future-digital-title {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 32px;
    letter-spacing: 0.5px;
    color: #182b68;
    margin-bottom: 40px;
  }
  .BrainhubCarousel .BrainhubCarousel__trackContainer {
    overflow: unset;
  }
  .BrainhubCarousel {
    .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft {
      z-index: 9;
    }
    .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
      z-index: 9;
      position: absolute;
      right: 60px;
    }
  }
  .future-digital-slider {
    width: 100%;
    .slider-card {
      &:hover {
        border: 0.5px solid #b8bbd8;
        box-shadow: 0px 2px 6px 1px rgba(184, 187, 216, 0.29);
        backdrop-filter: blur(14px);
      }
      height: 103px;
      background: #f8faff;
      border-radius: 6px;
      box-shadow: 0px 2px 6px 1px rgba(184, 187, 216, 0.29);
      backdrop-filter: blur(14px);
      margin: 5px;
      padding: 49px 37px 50px 35px;
      display: flex;
      flex-flow: column nowrap;
      border: 0.5px solid transparent;
      .card-title {
        font-family: "Merriweather Sans", sans-serif;
        font-size: 20px;
        font-weight: 700;
        color: #263238;
        margin-bottom: 13px;
      }
      .card-desc {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #37474f;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .future-digital-container {
    padding: 80px 0rem 80px 5rem !important;
  }
  .BrainhubCarouselItem {
    max-width: 400px !important;
    width: 400px !important;
  }
  .future-digital-container .future-digital-slider .slider-card .card-title {
    font-size: 17px !important;
  }
  .future-digital-container .future-digital-slider .slider-card .card-desc {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .future-digital-container {
    padding: 80px 0 80px 25px !important;
    .BrainhubCarousel
      .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
      right: 3px !important;
      margin-top: 8px;
    }
    .future-digital-slider .slider-card .card-title {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .future-digital-slider .slider-card .card-desc {
      font-size: 12px;
      line-height: 17px;
    }
    .future-digital-slider .slider-card {
      padding: 25px 17px 25px 15px;
    }
  }
  .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track{
    overflow: visible;
  }
}
@media only screen and (max-width:480px) {
  .future-digital-container{padding: 50px 15px 0px!important}
  .future-digital-container .BrainhubCarousel__container {
    // width: 76% !important;
    // margin: 0 auto;
    // max-width: 245px;
  }
  .future-digital-container .future-digital-slider .slider-card {
    margin: 10px !important;
    height: 10rem;
    max-height: 100%;
  }
}
.survey-cta-wrapper {
    padding: 63px 5rem 63px 5rem;
    .survey-cta {
      width: calc(100% - 130px);
      box-sizing: unset;
      height: 132px;
      background: #1e46ca;
      border-radius: 26px;
      display: flex;
      padding: 65px;
      .survey-cta-context {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
  
        flex-basis: 70%;
        .context-title {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 800;
          font-size: 30px;
          line-height: 18px;
          letter-spacing: 0.3px;
          color: #ffffff;
          margin-bottom: 60px;
        }
        .context-btn {
          width: 198px;
          height: 48px;
          background: #ffffff;
          border-radius: 10px;
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 800;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #1e46ca;
          border: none;
          cursor: pointer;
        }
      }
      .survey-cta-image {
        flex-basis: 25%;
        display: flex;
        justify-content: flex-end;
        > img {
          object-fit: unset;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .survey-cta-wrapper {
      padding: 0px 25px 80px 25px;
      .survey-cta {
        width: auto;
        height: auto;
        padding: 25px;
        flex-flow: column nowrap;
        .survey-cta-context {
          order: 2;
          align-items: center;
          flex-basis: 100%;
          .context-title {
            font-weight: 700;
            font-size: 22px;
            letter-spacing: 0.4px;
            margin-bottom: 30px;
            text-align: center;
            line-height: 36px;
          }
          margin-top: 20px;
        }
        .survey-cta-image {
          order: 1;
          justify-content: center;
          flex-basis: 100%;
        }
      }
    }
  }
  
.bg-1{background-color:#f5f8fc ;}
.ds-cards-container {
  max-width: 142rem;
  margin: 0 auto;
  padding: 107px 75px 70px 75px;
  background: #f5f8fc;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 100px 5rem 70px;
 
  .ds-cards-title {
    font-family: "Lato", sans-serif;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0.3px;
    color: #182b68;
    margin-bottom: 5px;
  }
  .ds-cards-desc {
    font-family: "Merriweather Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #37464f;
    margin-bottom: 94px;
  }
  .ds-cards-list {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-flow: nowrap;
    .ds-cards-item {
      &:hover {
        border: 0.5px solid #b8bbd8;
        box-shadow: 0px 2px 6px 1px rgba(180, 180, 184, 0.29);
        backdrop-filter: blur(14px);
      }
      display: flex;
      flex-flow: column;
      margin-right: 30px;
      margin-bottom: 30px;
      padding: 9px 31px 30px 31px;
      height: inherit;
      margin: 0px 0px;
      width: 18%;
      background: #ffffff;
      box-shadow: 0px 2px 6px 1px rgba(184, 187, 216, 0.29);
      backdrop-filter: blur(14px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 6px;
      border: 0.5px solid transparent;
      img {
        align-self: flex-start;
        margin-bottom: 21px;
        width: 56px;
      }
      .ds-cards-item-title {
        font-family: "Merriweather Sans", sans-serif;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 13px;
        color: #263238;
      }
      .ds-cards-item-desc {
        font-family: "Merriweather Sans", sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: #37464f;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ds-cards-container {
    padding: 107px 0px 70px 0px;
    .ds-cards-title {
      font-size: 24px;
      margin-bottom: 10px;
      text-align: center;
    }
    .ds-cards-desc {
      padding: 0 20px;
      margin-bottom: 45px;
    }
  }
  .ds-cards-list {
    .BrainhubCarouselItem {
      padding: 10px 0px 20px!important;
    }
  }

  .ds-cards-item.mobile-only {
    padding: 20px 20px !important;
    width: 190px !important;
    // height: 201px !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    height: auto;
    min-height: 230px;

    img {
      max-width: 3rem;
      margin-bottom: 10px !important;
    }
    .ds-cards-item-title {
      font-size: 16px !important;
      margin-bottom: 8px !important;
    }
    .ds-cards-item-desc {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
}

.values-container {
  padding: 100px 5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .values-title {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #182b68;
    margin-bottom: 60px;
  }
  .values-cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    width: 100%;
    .values-card {
      display: flex;
      flex-flow: column nowrap;
      width: 295px;
      max-width: 100%;
      margin-right: 55px;
      > span:first-child {
        margin-bottom: 11px;
      }
      .values-card-title {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #263238;
        margin-bottom: 11px;
      }
      .values-card-desc {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #37474f;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .values-container {
    padding: 80px 32px 50px 32px;
    .values-title {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    .values-cards {
      .values-card {
        margin-right: 0;
        margin-bottom: 30px;
        .values-card-title {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1425px) {
  .values-container {
    .values-cards {
      .values-card {
        margin-right: 20px;
        width: 250px;
        .values-card-title {
          margin-bottom: 5px;
        }
      }
    }
  }
}

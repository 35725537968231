.blog-header-container {
  background: #f5f8fc;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-flow: column nowrap;
  color: #fff;
  .blog-hero-container {
    padding: 70px 0 68px 0;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    color: #000;

    .blog-hero-content-wrapper {
      background-color: #fff;
      display: flex;
      flex-flow: row wrap;
      .blog-hero-img {
        order: 2;
        img {
          height: 100%;
          width: 100%;
          max-width: 100%;
        }
      }
      .blog-hero-content {
        order: 1;
        display: flex;
        flex-flow: column nowrap;
        padding: 20px 0 35px 0;
        background-color: #fff;
        width: 426px;
        max-width: 100%;
        padding: 20px 20px 35px 20px;
        .blog-hero-content-tag {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.2px;
          text-transform: uppercase;
          color: #182b68;
          margin-bottom: 26px;
        }
        .blog-hero-content-title {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: bold;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 0.3px;
          color: #37464f;
          margin-bottom: 10px;
        }
        .blog-hero-content-desc {
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #3d3d3d;
          height: 225px;
        }
        .blog-hero-content-author {
          margin-bottom: 12px;
          font-family: "Merriweather Sans", sans-serif;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #37474f;
        }
        .blog-hero-content-dateread {
          margin-bottom: 35px;
          font-family: "Merriweather Sans", sans-serif;
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;
          color: #767a83;
        }
      }
      .blog-hero-title {
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #182b68;
        margin-bottom: 12px;
      }
      .blog-hero-desc {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #3d3d3d;
        width: 40%;
        margin-bottom: 35px;
      }
      .blog-hero-btn {
        width: 263px;
        max-width: 100%;
        height: 45px;
        font-family: "Merriweather Sans", sans-serif;
        font-weight: bold;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        background: #1e46ca;
        box-shadow: 0px 6px 6px #e3e9ff;
        border-radius: 6.59185px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .blog-header-container {
    .blog-hero-container {
      padding: 50px 19px;

      .blog-hero-content-wrapper {
        flex-flow: column nowrap;
        box-shadow: 0px 2px 6px 1px rgba(184, 187, 216, 0.29);
        backdrop-filter: blur(14px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 6px;

        width: 100%;

        .blog-hero-img {
          order: 1;
          > img {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }
        }
        .blog-hero-content {
          width: auto;
          border-radius: 6px;
          padding: 20px 20px 0px 20px;
          .blog-hero-content-desc {
            height: 150px;
          }
        }
      }
    }
  }
}

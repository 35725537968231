.comingsoon{text-align: center;padding: 100px 0;}
.comingsoon img{width: 600px;display: inline-block;    margin: 50px 0;max-width: 90%;}
.getearly{background: #1e46ca;
    padding: 0 3rem;
    font-weight: 700;
    color: var(--white);
    border-radius: 6px;
    font-family: var(--openFont);
    font-size: 18px;
    line-height: 4rem;
    border:1px solid;
    border-color:#1e46ca;
    width: 300px;
    max-width: 90%;
    display: inline-block;
}
.comingsoon p {
    font-size: 23px;
    font-weight: 700;
}
.new-container{
    padding: 0px 15px !important;
}
.of-container {
  padding: 100px 5rem;
  background: #040c58;
  background: url("../../../../assets/images/of-bg.svg") center center no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  .of-title {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #ffffff;
    margin-bottom: 60px;
  }
  .of-content {
    display: flex;
    flex-flow: row wrap;

    .of-content-details {
      background: #f2f7ff;
      box-shadow: 0px 2px 6px 1px rgba(184, 187, 216, 0.29);
      backdrop-filter: blur(14px);
      border-radius: 6px;
      width: 491px;
      max-width: 100%;
      display: flex;
      flex-flow: column nowrap;
      padding: 45px 94px 31px 45px;
      height: inherit;
      .of-content-title {
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.3px;
        color: #263238;
        margin-bottom: 20px;
      }
      .of-content-desc {
        font-family: "Merriweather Sans", sans-serif;
        font-weight: 300;
        font-size: 16px;
    line-height: 32px;
        color: #37474f;
      }
    }
    .of-content-cards {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      flex-basis: 50%;
      .of-content-card {
        width: 280px;
        // height: 247px;
        background-color: #fff;
        margin-right: 35px;
        margin-bottom: 30px;
        border-radius: 6px;
        padding-bottom: 30px;
        display: flex;
        flex-flow: column nowrap;
        .of-content-card-img {
          img {
            max-width: 100%;
            height: 140px;
            object-fit: cover;
            width: 100%;
            object-position: top;
          }
          margin-bottom: 20px;
        }
        .of-content-card-title {
          margin-left: 20px;

          font-family: "Merriweather Sans", sans-serif;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #263238;
          margin-bottom: 7px;
        }
        .of-content-card-tags-wrapper {
          margin-left: 20px;
          margin-right: 20px;

          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          .of-content-card-tags {
            font-family: "Merriweather Sans", sans-serif;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: #080e26;
            display: flex;
            align-items: center;
            width: 50%;
          }
          .of-content-card-pill {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #182b68;
            width: 88.2px;
            height: 24px;
            border-radius: 12.973px;
            font-family: "Merriweather Sans", sans-serif;
            font-weight: bold;
            font-size: 9.08px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #f2f7ff;
          }
        }
      }
    }
  }
}
.mb-0{margin-bottom: 0px !important;}
@media only screen and (max-width: 767px) {
  .of-container {
    padding: 80px 20px;
    .of-title {
      font-size: 24px;
      line-height: 30px;
      text-align: left;
      margin-bottom: 31px;
    }
    .of-content {
      .of-content-cards {
        flex-basis: 100%;
        justify-content: space-evenly;
        .of-content-card {
          margin-right: 0;
          height: auto;
          width: 152px;
          padding-bottom: 15px;
          .of-content-card-img {
            margin-bottom: 10px;
          }
          .of-content-card-title {
            margin-left: 10px;
            font-size: 13.6112px;
            line-height: 13px;
          }
          .of-content-card-tags-wrapper {
            margin-left: 10px;
            margin-right: 0;
            .of-content-card-tags {width:100%;margin-bottom: 10px;}
          }
        }
      }
      .of-content-details {
        padding: 25px 15px;
        .of-content-title {
          font-size: 24px;
          line-height: 30px;
        }
        .of-content-desc {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1425px) {
  .of-container {
    padding: 80px 30px;
    .of-content {
      .of-content-cards {
        justify-content: space-evenly;
        .of-content-card {
          width: 235px;
          height: auto;
          margin-right: 0;
        }
      }
      .of-content-details {
        padding: 45px 45px 31px 45px;
        width: 465px;
      }
    }
  }
}

.siteHeader {
  > div:first-child {
    max-width: 142rem !important;
    padding: 0;
  }
  .container {
    .row .branding {
      .menuToggle {
        margin-right: 1rem;
        > img {
          width: 1.5em;
          height: 1.1em;
        }
      }
    }
  }

  padding: 0.9rem 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  max-width: 100vw;
  top: 0;
}

.siteHeader::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: url("../../../../assets/images/header-bg-std.jpg") no-repeat
    center/cover;
  top: 0;
  bottom: 0;
  pointer-events: none;
  height: 0;
  transition: 0.3s;
}

.siteHeader.affix::before {
  height: 100%;
}

.siteHeader .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}
.navlink-mobile-only {
  display: none;
}
.mainNav {
  .dropdown-submenu:hover {
    a > img {
      transform: rotate(180deg);
    }
    .sub-menu {
      display: block !important;
    }
  }
  .dropdown-submenu {
    position: relative;
    > a {
      display: flex;
    }
    a > img {
      margin-left: 8px;
      object-fit: unset;
    }
    .sub-menu {
      display: none !important;
      left: 25px;
      position: absolute;
      background: #fff;
      padding: 15px 0;
      width: 216px;
      border-radius: 6px;
      -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);

      li a:hover {
        background: #f5f8fc;
        border-left: 2px solid #000;
        font-weight: bold;
      }
      li a {
        padding: 10px 70px 10px 15px;
        color: #181f39;
        margin-bottom: 5px;
        font-family: "Merriweather Sans", sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 0.3px;
        color: #181f39;
      }
      li:last-child a {
        &:hover {
          font-weight: bold;
          border-radius: 0;
        }
        font-weight: normal;
        margin: 0;
        padding: 10px 70px 10px 15px;
      }
    }
  }
}
.mainNav li {
  .navlink-dropdown {
    border: none;
    background: none;
    .navlink-dropdown-header {
      outline: none;
      padding: 0;
      display: flex;
      color: #181f39;
      font-weight: 600;
      font-size: 16px;
      font-family: var(--merriFont);
      img {
        order: 2;
        margin-left: 10px;
        margin-top: 4px;
      }
    }
    .expanded-links {
      padding-left: 0px;
      border-left: 2px solid #2b324f;
      .navlink-item {
        cursor: pointer;
        color: #181f39;
        &:hover {
          background: #f5f4fd;
        }
        padding: 10px 0 !important;
        border-radius: 3px;
        margin-bottom: 10px !important;
        padding-left: 15px !important;
        &:first-child {
          display: block !important;
        }
        &:last-child {
          display: inline-block !important;
          margin-bottom: 0px !important;
          a {
            &:hover {
              background: #f5f4fd;
            }
            margin: 0 !important;
            padding: 0;
            text-align: left !important;
          }
        }
        border-bottom: none;
      }
    }
  }
}

.mainNav li a {
  display: block;
  padding: 0 2rem;
  line-height: 4rem;
  font-size: 1.5rem;
  color: var(--white);
}

.mainNav li:last-child a {
  color: var(--blue);
  padding: 0 3rem;
  font-weight: 600;
  background: var(--white);
  margin-left: 4rem;
  border-radius: 0.2rem;
  font-family: var(--openFont);
}

.branding img {
  max-width: 15.5rem;
  display: block;
  width: 158px;
  max-height: 50px;
  margin: 2px 0px;
}

.menuToggle {
  position: relative;
  z-index: 2;
  cursor: pointer;
}
html {
  font-size: 62.5%;
}
.container {
  max-width: 142rem;
  margin: 0 auto;
  padding: 0 5rem;
}
.survey-cta-wrapper {
  padding: 63px 0px !important;
}
.survey-cta-wrapper .survey-cta {
  width: 82%;
  max-width: 142rem;
  margin: 0 auto;
}

.card {
  border: 0.5px solid transparent;
}
.new-container {
  padding: 0 !important;
  margin: 0 auto;
  width: 100%;
  max-width: 1165px;
}

@media only screen and (max-width: 1440px) {
  .siteHeader > div:first-child,
  .homepage-container .container,
  .ds-cards-container,
  .solutions-container .solutions-items,
  .ds-stats-container,
  .survey-cta-wrapper,
  .transformation-container,
  .footer-container,
  .container {
    max-width: 125rem !important;
  }
}

@media only screen and (max-width: 1280px) {
  .siteHeader > div:first-child,
  .homepage-container .container,
  .ds-cards-container,
  .solutions-container .solutions-items,
  .ds-stats-container,
  .survey-cta-wrapper,
  .transformation-container,
  .footer-container,
  .container {
    max-width: 110rem !important;
  }
}

.bannerBlock .cta {
  max-width: 300px;
}
@media only screen and (max-width: 768px) {
  #header {
    .mainNav li a {
      color: #181f39;
      font-weight: 600;
      font-size: 16px;
      font-family: var(--merriFont);
    }
    .mob-nav-buttons {
      display: flex;
    }
    .mainNav {
      padding-top: 5rem;
      ul {
        flex-flow: column;
        .navlink-mobile-only {
          display: block !important;
        }

        li {
          &:first-child {
            display: none;
          }
          padding: 20px 0;
          border-bottom: 1px solid #efefef;
          width: 80%;
          margin-bottom: 0rem !important;
          a {
            padding-left: 0;
          }
        }
      }
    }
    .mainNav {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #fff;
      transform: translateX(100%);
      transition: 0.4s;
    }
    .mainNav ul {
      display: block;
    }

    .mainNav li:last-child a {
      margin: 3rem 3rem 0;
      text-align: center;
    }
    .mainNav li a {
      line-height: 5rem;
      font-size: 1.6rem;
    }
  }
  .siteHeader > div:first-child,
  .homepage-container .container,
  .ds-cards-container,
  .solutions-container .solutions-items,
  .ds-stats-container,
  .survey-cta-wrapper,
  .transformation-container,
  .footer-container,
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
    max-width: 90% !important;
    margin: 0 auto imp !important;
  }
  .transformation-container .transformation-items .transformation-app img {
    max-width: 100%;
  }
  .transformation-container
    .transformation-items
    .transformation-workflow
    .transformation-workflow-list-wrapper {
    margin-left: 10px;
  }
  .row-flex .col-sm-6 {
    padding: 0 !important;
  }
  .siteHeader .branding {
    justify-content: space-between !important;
  }
  .header-container .header-content {
    width: 50% !important;
  }
  .ds-cards-list .BrainhubCarouselItem {
    width: 260px !important;
    min-width: 200px !important;
    margin: 0 !important;
  }
}
@media only screen and (max-width: 480px) {
  .hamburger .hamburger-box {
    top: 2px;
  }
  .hamburger {
    &.is-active {
      .hamburger-inner {
        background-color: #000 !important;

        &::after {
          background-color: #000 !important;
        }
        &::before {
          background-color: #000 !important;
        }
      }
    }
    z-index: 2;
    outline: none;
    padding: 0;
    .hamburger-box {
      top: 2px;
    }
    .hamburger-inner {
      margin-top: -1px;
      width: 35px;
      background: #fff;
      height: 3.5px;

      &::after {
        bottom: -8px;
        width: 35px;
        background: #fff;
        height: 3.5px;
      }
      &::before {
        top: -8px;
        width: 35px;
        background: #fff;
        height: 3.5px;
      }
    }
  }
  .sticky-top {
    top: 70px !important;
  }
  .mainNav ul {
    display: block;
    padding-left: 40px;
  }
  .mainNav li a {
    display: block;
    padding: 0 2rem;
    line-height: 4rem !important;
    font-size: 1.5rem;
    color: var(--white);
  }
  .mainNav {
    padding-top: 9rem !important;
  }
  .mob-ask-for-demo {
    display: flex;
    align-self: center;
    color: #303f9f;
    background: var(--white);
    padding: 0 1.5rem;
    font-weight: 600;
    border-radius: 0.2rem;
    font-family: var(--openFont);
    line-height: 4rem !important;
  }
  .siteHeader {
    padding: 21px 0 !important;
  }
  .header-container .header-content .header-content-input {
    justify-content: center;
    width: max-content;
    margin: 0 auto;
  }
  .dbs-container {
    padding: 50px 15px !important;
  }
  .of-container .of-title {
    text-align: center;
  }
  .header-container .header-content,
  .erfw-container .erfw-cards-list,
  .erfw-container .erfw-cards-list .erfw-card,
  .dbs-container .dbs-safety-cards .dbs-safety-card {
    width: 100% !important;
  }
  .transformation-container {
    padding: 50px 15px !important;
    height: auto;
  }
  .of-container .of-content .of-content-details {
    padding: 25px 15px !important;
    margin-top: 20px !important;
  }
  .of-container .of-content .of-content-cards {
    flex-basis: 100%;
    justify-content: space-between !important;
  }
  .of-container .of-content .of-content-cards .of-content-card {
    width: 48% !important;
  }
  .values-container {
    padding: 50px 15px !important;
  }
  .container {
    padding: 0 !important;
  }
  .transformation-container
    .transformation-items
    .transformation-workflow
    .transformation-workflow-list-wrapper
    .transformation-workflow-subscribe
    input {
    width: 130px !important;
  }
  .BrainhubCarouselItem {
    margin-left: 0 !important;
  }
  .transformation-container
    .transformation-items
    .transformation-workflow
    .transformation-workflow-list-wrapper
    .transformation-workflow-subscribe {
    margin-bottom: 40px;
    justify-content: center;
  }
  .transformation-container
    .transformation-items
    .transformation-workflow
    .transformation-workflow-list-wrapper
    .transformation-workflow-list
    .transformation-workflow-item
    span {
    font-size: 14px !important;
  }
  .future-digital-container
    .BrainhubCarousel
    .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft {
    z-index: 9;
    left: 3px;
    position: absolute;
  }
  .bannerText input.border-0.cta.fill.large.blue {
    padding: 0px 15px !important;
    min-width: auto;
  }
  .bannerBlock {
    height: auto !important;
  }
  section.academyFeatures {
    // margin-top: 30px;
  }
  .values-container .values-cards .values-card {
    width: 100% !important;
  }
  .header-container .header-content .header-content-input input {
    width: 133px !important;
  }
  .header-container .header-content .header-content-input span {
    font-size: 14px !important;
    padding: 19px 10px !important;
    margin-left: 0 !important;
  }
  .ds-cards-container,
  .solutions-container,
  .ds-stats-container {
    padding: 50px 0 50px !important;
  }
  .siteHeader > div:first-child {
    max-width: 100% !important;
  }
  .branding img {
    max-width: 8.5rem !important;
  }
  a.mob-ask-for-demo.menuToggle {
    font-size: 15px;
    line-height: 2.5rem;
  }
  .siteHeader {
    background: var(--blue3);
    padding: 1.5rem 0;
    height: 7rem;
    align-items: center;
    height: auto !important;
  }
  .future-digital-container
    .BrainhubCarousel
    .BrainhubCarousel__trackContainer {
    overflow: unset;
    width: 100%;
  }
  .header-container.home-head,
  .ap-container .bannerBlock {
    height: 105vh;
  }
  .future-digital-container .BrainhubCarousel__container {
    width: 76% !important;
    margin: 0 auto;
    max-width: 225px;
  }
  .future-digital-container .future-digital-slider .slider-card {
    margin: 10px !important;
    height: auto !important;
  }
  .survey-cta-wrapper .survey-cta .survey-cta-context .context-title {
    line-height: 30px !important;
  }
}
.mainNav li a {
  opacity: 0.8;
}
.mainNav li a:hover {
  opacity: 1;
}
.future-digital-container .BrainhubCarousel .BrainhubCarousel__trackContainer {
  overflow: unset;
  width: 100%;
}
.header-container.home-head,
.ap-container .bannerBlock {
  height: 105vh;
}

// .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
//   overflow: visible;
//   width: 100% !important;
// }
// .BrainhubCarousel__container {
//   width: 76% !important;
//   overflow: hidden;
//   margin: 0 auto;
// }
// .BrainhubCarouselItem {
//   width:250px  !important;
//   min-width: 250px !important;}
.header-container .header-content .header-content-input {
  border: 0.5px solid transparent;
}

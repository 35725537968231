#ask-demo-page {
  display: flex;
  flex-direction: column;
  border: none;
  > div:nth-child(2) {
    margin-top: 60px;
  }
  .siteHeader {
    &::before {
      height: 100%;
    }
    padding: 0.5rem 0;
  }

  // .d-flex {
  //   display: flex;
  // }

  // .cp-navbar-container {
  //   * {
  //     color: #fff;
  //     font-family: "Open Sans", sans-serif;
  //   }
  // }
}
#ask-demo-page {
  section {
    padding: 100px 0px;
    h2 {
      font-size: 3.2rem;
      line-height: 3.5rem;
      letter-spacing: 0.3px;
      font-weight: 900;
      margin-bottom: 2rem;
      color: var(--darkBlue);
      font-family: var(--latoFont);
    }
    .shadowBox {
      background: #ffffff;
      box-shadow: 0px 14.903px 24.8384px rgba(153, 156, 157, 0.25);
      border-radius: 6px;
      padding: 6% 5% 13%;
      position: relative;
      button {
        font-family: Merriweather Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 23.4211px;
        line-height: 47px;
        text-align: center;
        color: #f2f7ff;
        background: #1e46ca;
        // box-shadow: 0px 14.903px 24.8384px rgb(153 156 157 / 25%);
        border-radius: 0px 0px 6px 6px;
        border: none;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    .shadowBox-coniner {
      display: flex;
      margin: 0 -15px;
      .form-container {
        width: 100%;
        display: flex;
      }
      .w-50 {
        width: 50%;
        padding: 0px 15px;
      }
      .w-100 {
        width: 100%;
        padding: 0px 15px;
      }
      .group {
        margin-bottom: 25px;
        label {
          display: block;
          font-size: 14px;
          line-height: 18px;
          color: #2b324f;
          font-family: Merriweather Sans;
          margin-bottom: 10px;
        }
        .form-control {
          background: #ffffff;
          border: 1.5px solid #f1f3f5;
          box-sizing: border-box;
          border-radius: 5px;
          height: 42px;
          padding: 10px 15px;
          width: 100%;
          font-size: 14px;
          outline: none;
          box-shadow: none;
          svg {
            width: 19px;
            height: 19px;
            fill: #1e46ca;
          }
        }
        textarea.form-control {
          height: auto;
        }
      }
    }
  }
  .shadowBox-right {
    padding-left: 15%;
    .right-content {
      .d-flex {
        img {
          width: 78px;
        }
        margin-bottom: 30px;
        align-items: center;
      }
      .details {
        font-family: Merriweather Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #37474f;
        padding-left: 15px;
      }
      margin-bottom: 20px;
    }
    h3 {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 35px;
      letter-spacing: 0.300158px;
      color: #263238;
      margin-bottom: 30px;
      margin-top: 20px;
    }
    .social {
      img {
        width: 45px;
      }
      a {
        margin-right: 25px;
      }
    }
  }
  .succes-box {
    .d-flex {
      flex-wrap: wrap;
    }
    margin-top: 30px;
    .bottom-box {
      background: #f5f8fc;
      border-radius: 6px;
      padding: 30px;
      h3 {
        font-weight: 800;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.300158px;
        color: #263238;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #263238;
      }
      label {
        font-size: 14px;
        line-height: 18px;
        color: #606980;
        margin-bottom: 8px;
        display: inline-block;
      }
      .d-flex {
        margin-top: 20px;
        margin-bottom: 20px;
        .w-50 {
          padding: 0;
        }
        p {
          font-size: 14px;
          line-height: 18px;
          color: #263238;
        }
      }
      .bottom-text {
        font-size: 14px;
        line-height: 18px;
        /* identical to box height, or 129% */

        color: #606980;
      }
      a {
        font-family: Merriweather Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #f5f8fc;
        background: #182b68;
        border-radius: 20px;
        padding: 10px 20px;
        height: 37px;
        display: inline-block;
        margin-bottom: 20px;
      }
    }
    .top-box {
      background: #182b68;
      border-radius: 6px 6px 0px 0px;
      padding: 30px;
      color: #fff;
      .datebox {
        margin-top: 30px;
      }
      h3 {
        color: #fff;
        margin: 0;
        font-family: Merriweather Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 24px;
        /* identical to box height, or 80% */
      }
      h5 {
        font-family: Merriweather Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #f5f8fc;
        margin-bottom: 15px;
      }
      img.dateimg {
        width: 90px;
      }
      .p-0 {
        padding: 0;
      }
      .align-center {
        align-items: center;
      }
      p {
        margin-left: auto;
      }
    }
  }
  .form-control::placeholder,
  .react-dropdown-select-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b8bbd8;
    opacity: 1; /* Firefox */
    font-size: 14px;
  }

  .form-control:-ms-input-placeholder,
  .react-dropdown-select-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b8bbd8;
    font-size: 14px;
  }

  .form-control::-ms-input-placeholder,
  .react-dropdown-select-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b8bbd8;
    font-size: 14px;
  }
  .bg-1 {
    background-color: #f5f8fc;
  }
  @media (min-width: 768px) {
    .menuToggle,
    .mob-nav-buttons {
      display: none;
    }
    .mobile-only {
      display: none !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .succes-box .top-box .d-flex p {
      margin-left: 0;
      margin-top: 10px;
    }
    .shadowBox-right {
      padding-left: 0;
    }
    section {
      padding: 7rem 0 !important;
    }
    section .shadowBox {
      margin-bottom: 30px;
    }
    .w-50 {
      width: 100% !important;
    }
    section .shadowBox-coniner .form-container,
    section .shadowBox-coniner {
      flex-wrap: wrap;
    }
    .fp-container {
      > div:nth-child(2) {
        margin-top: 60px;
      }

      .siteHeader {
        background: var(--blue3);
        padding: 1rem 0;
      }
      .siteHeader::before {
        display: none;
      }

      .siteHeader .branding {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      .mainNav {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #fff;
        padding-top: 9rem;
        transform: translateX(100%);
        transition: 0.4s;
      }
      .mainNav ul {
        display: block;
      }

      .mainNav li:last-child a {
        margin: 3rem 3rem 0;
        text-align: center;
      }
      .mainNav li a {
        line-height: 2.5rem;
        font-size: 1.6rem;
      }

      .container {
        // padding: 0 !important;
        a {
          z-index: 2;
          text-decoration: none;
        }
      }

      .mainNav {
        padding-top: 5rem;
        ul {
          flex-flow: column;
          list-style: none;
          .navlink-mobile-only {
            display: block !important;
          }

          li {
            &:first-child {
              display: none;
            }
            padding: 20px 0;
            border-bottom: 1px solid #efefef;
            width: 80%;
            margin-bottom: 0rem !important;
            a {
              padding-left: 0;
            }
          }
        }
      }

      .mainNav li a {
        color: #181f39;
        font-weight: 600;
        font-size: 16px;
        font-family: var(--merriFont);
      }
      .hamburger {
        z-index: 2;
        outline: none;
        padding: 0;
        .hamburger-box {
          top: 2px;
        }
        .hamburger-inner {
          margin-top: -1px;
          width: 35px;
          background: #fff;
          height: 3.5px;

          &::after {
            bottom: -8px;
            width: 35px;
            background: #fff;
            height: 3.5px;
          }
          &::before {
            top: -8px;
            width: 35px;
            background: #fff;
            height: 3.5px;
          }
        }
      }
      .is-active {
        .hamburger-inner {
          background: #000;
          margin-top: 0;
          width: inherit;

          &::after {
            bottom: 0;
            width: inherit;
            height: inherit;
            background: #000;
          }
          &::before {
            background: #000;
          }
        }
      }

      .siteHeader > div:first-child {
        width: 100%;
      }
      .branding img {
        max-width: 8.5rem;
      }

      .mob-ask-for-demo {
        color: var(--blue) !important;
        background: var(--white);
        padding: 0 1rem;
        font-weight: 600;
        border-radius: 0.2rem;
        font-family: var(--openFont);
        line-height: 2.5rem;
      }
      span.mob-nav-buttons {
        display: flex;
        align-items: center;
      }
    }
    .menu-active {
      .mainNav li:last-child {
        display: none;
      }
    }
    .menu-active .mob-ask-for-demo {
      color: var(--white) !important;
      padding: 0 1rem;
      font-weight: 600;
      line-height: 2.5rem;
      background: #1e46ca;
      border-radius: 0.2rem;
      font-family: var(--openFont);
    }
    .menu-active .mainNav {
      transform: translateX(0);
    }
    .menu-active {
      overflow: hidden;
    }
  }
}

.err-message {
  color: rgb(255, 93, 93);
}

.askDemo {
  &__id {
    min-width: 8rem !important;
    height: 7rem !important;
    border: 1px solid rgba(255, 255, 255, 0.363);
    padding: 0 !important;
    display: flex;
    border-radius: 6px 6px;
    overflow-y: hidden;
    &__h1 {
      text-align: center;
      font-size: 5.3rem;
      font-weight: 600;
      padding-top: 4px;
    }
  }
}